<template>
  <b-container class="finished__container text-center">
    <b-container v-if="finished" class="gift__box">
      <b-img class="mb-3" :src="require('@/assets/images/finish01.jpg')" fluid></b-img>
      <!-- <h1 class="finished__header">{{ base.finishedHeader }}</h1> -->
      <p class="finished__content" v-html="base.finishedDescription"></p>
      <!-- <em v-html="base.finishedGreetings"></em> -->

      <b-button
        variant="success"
        class="finished__button w-100 mt-2"
        size="lg"
        @click="redirectFinish"
      >
        {{ base.finishedButton }}
      </b-button>
    </b-container>
    <b-container class="server__container" v-else>
      <h1 class="finished__header">{{ base.serverHeader }}</h1>
      <transition name="fade">
        <p v-for="(message, i) of messages" :key="i">
          <b-spinner
            v-if="message != 'Prüfung abgeschlossen!'"
            small
          ></b-spinner>
          <b-icon-check-circle v-else></b-icon-check-circle>
          {{ message }}
        </p>
      </transition>
    </b-container>
  </b-container>
</template>

<script>
import base from "../config/base";

export default {
  data() {
    return {
      base,
      finished: false,
      messages: [],
    };
  },
  methods: {
    redirectFinish() {
      //window.location.href = base.urlRedirect;
      this.$router.push("/calculator");
    },
    serverLoading() {
      this.messages.push(base.serverMessages[0]);
      setTimeout(() => {
        this.messages.shift([0]);
        this.messages.push(base.serverMessages[1]);
        setTimeout(() => {
          this.messages.shift([0]);
          this.messages.push(base.serverMessages[2]);
          setTimeout(() => {
            this.messages.shift([0]);
            this.messages.push(base.serverMessages[3]);
            setTimeout(() => {
              this.finished = true;
            }, 1000);
          }, 2000);
        }, 1000);
      }, 2000);
    },
  },
  created() {
    this.serverLoading();
  },
};
</script>

<style scoped>
.finished__container {
  margin-top: 20px;
}
.finished__header {
  text-transform: uppercase;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}
.finished__content {
  letter-spacing: 1px;
}
.finished__button {
  font-weight: bold;
  text-transform: uppercase;
  border: 0px;
  background-color: #fd8a02;
}
</style>
